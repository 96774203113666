import './App.scss';
import './globalStyles.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Route, Routes } from "react-router-dom";
import HomePage from './Pages/HomePage/HomePage';
import { navLinks } from './Layout/Constants/NavLinks';
import ContactUs from './Pages/ContactUs/ContactUs';
import WorkDetails from './Pages/WorkDetails/WorkDetails';
import Logo from "./Assets/Logo.svg"


function App() {

  return (
    <div >
      {/* <div className='startingAnimation'>
        <div className="containerr">
          <div className="image">
            <img className="img" src={Logo} width="200" viewBox="0 0 250 250" />
          </div>
        </div>
      </div> */}
      <Routes >
        <Route path="/" exact element={<HomePage />} />
        {navLinks?.map((item) => {
          return (
            <Route path={item?.path} exact={item?.exact} element={item?.element} />
          )
        })}
        <Route path="/contact" exact  element={<ContactUs />} />
        <Route path="/work/:workTitle" exact  element={<WorkDetails />} />
      </Routes>
    </div>
  );
}

export default App;
