import React, { useState } from 'react'
import BasicLayout from '../../Layout/BasicLayout/BasicLayout'
import styles from './Work.module.scss'
import LastContainer1 from '../../Components/LastContainer/LastContainer1'
import { FiArrowUpRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { workArray } from '../../Layout/Constants/ProjectsDetails'

const Work = () => {
  const navigate = useNavigate()
  const [showLength, setShowLength] = useState(3)
  const [showbtn, setShowbtn] = useState('block')

  const showAll = () => {
    setShowLength(workArray.length)
    setShowbtn('none')
  }


  return (
    <BasicLayout>
      <div className={`mainPaddingContainer ${styles.container1}`}>
        <h1 className={styles.heading1}><span>Our</span> Projects.</h1>
      </div>
      {workArray.slice(0, showLength)?.map((work, index) => {
        return (
          <div className={`mainPaddingContainer container-fluid ${styles.workContainer}`}>
            <div className={`row align-items-center ${index % 2 === 0 ? null : 'flex-sm-row-reverse'}`}>
              <div className='col-12 col-sm-6'>
                <img src={work?.workImg} className={styles.workImage} alt='workImage' />
              </div>
              <div className='col-12 col-sm-6'>
                <div className={styles.textContainer}>
                  <div className={`container-fluid`}>
                    <div className='row'>
                      <img src={work?.icon} className={styles.workIcon} style={{ height: '60px', width: 'auto' }} alt='workIcon' />
                      <h1 className={styles.workTitle}>{work?.title}</h1>
                      <p className={styles.workDesc}> {work?.description}</p>
                      <button className={` btn_medium`} onClick={() => navigate(`/work/${work.title}`)}>View Case Study <FiArrowUpRight /></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button className={`btn_medium`} style={{ display: showbtn }} onClick={showAll}>View All <FiArrowUpRight /></button>
      </div>
      < LastContainer1 />
    </BasicLayout>
  )
}

export default Work