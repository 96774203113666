import React from 'react'
import BasicLayout from '../../Layout/BasicLayout/BasicLayout'
import styles from './About.module.scss'
import aboutImg from '../../Assets/about/aboutImg.png'
import logo1 from '../../Assets/about/logo1.svg'
import logo2 from '../../Assets/about/logo2.svg'
import logo3 from '../../Assets/about/logo3.svg'
import logo4 from '../../Assets/about/logo4.svg'
import logo5 from '../../Assets/about/logo5.svg'
import logo6 from '../../Assets/about/logo6.svg'
import logo7 from '../../Assets/about/logo7.svg'
import logo8 from '../../Assets/about/logo8.svg'

import LastContainer2 from '../../Components/LastContainer/LastContainer2'

const AboutPage = () => {
  const brandsArray = [
    { icon: logo1 },
    { icon: logo2 },
    { icon: logo3 },
    { icon: logo4 },
    { icon: logo5 },
    { icon: logo6 },
    { icon: logo7 },
    { icon: logo8 },
  ]
  return (
    <BasicLayout>
      <div className={`mainPaddingContainer container-fluid ${styles.container1}`}>
        <div className='row'>
          <div className='col-12 col-sm-6'>
            <h1 className={styles.heading1}>Bringing Design <br /><span>to life</span></h1>
          </div>
          <div className='col-12 col-sm-6'>
            <p className={styles.text1}>Our canvas is broad and we look forward to fill it with many of your success stories!! Our focus is on web and mobile design and our passion is to design unique, strategic and elegant interfaces! Let us transform your story into a visual treat and make your brand a force to reckon with!</p>
          </div>
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img src={aboutImg} alt='aboutImg' />
      </div>
      <div className={`mainPaddingContainer container-fluid ${styles.container2}`}>
        <div className='row'>
          <div className='col-12'>
            <h1 className={styles.heading2}>About Us</h1>
          </div>
          <div className='col-12'>
            <p className={styles.text1}>With an aim to excel in the IT and Digital Solutions, we are a Bangalore Based IT and Services Company that focuses on delivering state-of-the-art customized digital products and web and mobile design solutions to our clients. Digitalization is the key mantra in enhancing and efficiency running a business in today’s scenario and that is our USP. We have an excellent team of highly competent and empowered professionals who choose quality over quantity and treat each project as the most important one yet! We love what we do and that reflects in the services we provide!</p>
          </div>
        </div>
      </div>
      <div className={`mainPaddingContainer container-fluid ${styles.container3}`}>
        <div className='row'>
          <p className={styles.companyColabText}>50+ Clients</p>
        </div>
        <div className={`${styles.brandContainer}`}>
          {brandsArray?.map((brand) => {
            return (
              <div className={`${styles.brandImageContainer}`}>
                <img src={brand?.icon} alt='brandImage' />
              </div>
            )
          })}
        </div>
      </div>
      <LastContainer2 />
    </BasicLayout>
  )
}

export default AboutPage