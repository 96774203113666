// import aseelIcon from '../../Assets/Work/aseelIcon.svg'

import bawsalaIcon from '../../Assets/Work/bawsalaIcon.svg'
import bawsalaImage from '../../Assets/Work/bawsalaImage.png'
import bawsala_headerBackground from '../../Assets/Work/workDetails/bawsala/bawsala_headerBackground.png'
import bawsala_HeaderImage from '../../Assets/Work/workDetails/bawsala/bawsala_HeaderImage.svg'
import bawsala_section2 from '../../Assets/Work/workDetails/bawsala/bawsala_section2.png'
import bawsala_section3 from '../../Assets/Work/workDetails/bawsala/bawsala_section3.png'
import bawsala_section4 from '../../Assets/Work/workDetails/bawsala/bawsala_section4.png'
import bawsala_section5_background from '../../Assets/Work/workDetails/bawsala/bawsala_section5_background.png'

import aseelIconWhite from '../../Assets/Work/aseel/aseelIconWhite.svg'
import aseelImage from '../../Assets/Work/aseelImage.png'
import aseel_section2 from '../../Assets/Work/aseel/aseel_section2.png'
import aseel_section3 from '../../Assets/Work/aseel/aseel_section3.png'
import aseel_section4Left from '../../Assets/Work/aseel/aseel_section4Left.png'
import aseel_section4Right from '../../Assets/Work/aseel/aseel_section4Right.png'
import aseel_section5_background from '../../Assets/Work/aseel/aseel_section5_background.png'


import HKIcon from '../../Assets/Work/hk/HKIcon.svg'
import HKImage from '../../Assets/Work/hk/HKImage.png'
import hk_HeaderImage from '../../Assets/Work/hk/hk_HeaderImage.png'
import hk_headerBackground from '../../Assets/Work/hk/hk_headerBackground.png'
import hk_section2 from '../../Assets/Work/hk/hk_section2.png'
import hk_section3 from '../../Assets/Work/hk/hk_section3.png'
import hk_section4 from '../../Assets/Work/hk/hk_section4.png'
import hk_section5_background from '../../Assets/Work/hk/hk_section5_background.png'

import aljIconWhite from '../../Assets/Work/alj/aljIconWhite.svg'
import ajlImage from '../../Assets/Work/alj/ajlImage.png'
import ajl_section2 from '../../Assets/Work/alj/ajl_section2.png'
import ajl_section3 from '../../Assets/Work/alj/ajl_section3.png'
import ajl_section4Left from '../../Assets/Work/alj/ajl_section4Left.png'
import ajl_section4Right from '../../Assets/Work/alj/ajl_section4Right.png'
import ajl_section5_background from '../../Assets/Work/alj/ajl_section5_background.png'

import molemIcon from '../../Assets/Work/molem/molemIcon.svg'
import molem_headerBackground from '../../Assets/Work/molem/molem_headerBackground.svg'
import molemImage from '../../Assets/Work/molem/molemImage.png'
import molem_section2 from '../../Assets/Work/molem/molem_section2.png'
import molem_section3 from '../../Assets/Work/molem/molem_section3.png'
import molem_section4Left from '../../Assets/Work/molem/molem_section4Left.png'
import molem_section4Right from '../../Assets/Work/molem/molem_section4Right.png'
import molem_section5_background from '../../Assets/Work/molem/molem_section5_background.png'

import investorsMineIcon from '../../Assets/Work/investorsMine/investorsMineIcon.svg'
import IM_HeaderImage from '../../Assets/Work/investorsMine/IM_HeaderImage.png'
import im_section2 from '../../Assets/Work/investorsMine/im_section2.png'
import im_headerBackground from '../../Assets/Work/investorsMine/im_headerBackground.png'
import im_section3 from '../../Assets/Work/investorsMine/im_section3.png'
import im_section4 from '../../Assets/Work/investorsMine/im_section4.png'
import im_section5_background from '../../Assets/Work/investorsMine/im_section5_background.png'



export const workArray=[
    {
        icon: bawsalaIcon,
        workImg: bawsalaImage,
        title: 'Bawsala',
        type:'mobile',
        description: 'BAWSALA is an addressing system that redefines addresses from the ordinary, old, language-specific, complex and multi-line addresses into a simple, single line, elegant, digitised, and searchable Alphanumeric code',
        image: bawsala_HeaderImage,
        backgroundImg: bawsala_headerBackground,
        backgroundSize:'contain',
        backgroundPosition:'bottom center',
        otherprojectImg:bawsalaImage,
        section1: {
            title: 'Creating innovative streaming experiences for communities.',
            ourRole: 'UX/UI ,Product Design, Branding, Development, Testing, Deployment',
            company: 'Bawsala'
        },
        sections: [
            {
                title: 'Search Place',
                description: 'We have assigned unique codes to each and every house, apartment, villa and buildings. Search places using Bawsala Code (like JDRW 1558) or Address.',
                img: bawsala_section2
            },
            {
                title: 'Select Building',
                description: 'Tap on top of any building to get its Bawsala Code. Borders will be drawn and its enterences will be placed.',
                img: bawsala_section3
            },
            {
                title: 'Navigate to the address',
                description: 'To navigate to any place, navigate using Google Maps, Apple Maps or Waze.',
                img: bawsala_section4
            }
        ],
        imgSection: { img: bawsala_section5_background }
    },
    {
        icon: HKIcon,
        workImg: HKImage,
        title: 'Home Kitchen',
        type:'mobile',
        description: 'Living in a different city? Missing home cook food? Homekitch brings food right from the kitchen of home Chef.',
        image: hk_HeaderImage,
        backgroundImg: hk_headerBackground,
        backgroundSize:'cover',
        backgroundPosition:'top center',
        otherprojectImg:HKImage,
        section1: {
            title: 'Creating innovative streaming experiences for communities.',
            ourRole: 'UX/UI ,Product Design, Branding, Development, Testing, Deployment',
            company: 'Home Kitchen'
        },
        sections: [
            {
                title: 'Explore Kitchen',
                description: 'Checkout kitchen details like cuisine, ratings, photos, reviews and chef details.',
                img: hk_section2
            },
            {
                title: 'Order Details',
                description: 'Preview you order details by confirming your items and address.',
                img: hk_section3
            },
            {
                title: 'Track your order',
                description: 'Get an live update of your order with details like time, order status and driver details.',
                img: hk_section4
            }
        ],
        imgSection: { img: hk_section5_background }
    },
    {
        icon: aseelIconWhite,
        workImg: aseelImage,
        title: 'Aseel',
        type:'web',
        description: 'Aseel is a crowdsource funding platform in Real estate. Made it easier for people to invest in real estate.',
        image: aseelImage,
        // backgroundImg: bawsala_headerBackground,
        otherprojectImg:bawsala_HeaderImage,
        section1: {
            title: 'Creating innovative streaming experiences for communities.',
            ourRole: 'UX/UI, Product Design, Web Development, Testing, Deployment',
            company: 'Aseel'
        },
        section2:{
            img: aseel_section2
        },
        section3:{
            title:'Investment Opportunity',
            description:'Find details of the investments like- Duration of investment, total value of the project, Interest rate, location and many other things that will help user to understand and invest in the opportunity.',
            img:aseel_section3
        },
        section4:{
            // title:'Lorem ipsum',
            // description:'LoLorem ipsum dolor sit amet, consectetur adipiscing elit. Potenti maecenas commodo habitant suspendisse pretium. Nec sem turpis purus mauris. Elementum ultrices eu arcu pretium lectus ante faucibus accumsan. Sagittis, tellus justo, sit mi a ut diam donec. Arcu.',
            imgLeft:aseel_section4Left,
            imgRight:aseel_section4Right
        },
        imgSection: { img: aseel_section5_background }
    },
    {
        icon: aljIconWhite,
        workImg: ajlImage,
        title: 'ALJ',
        type:'web',
        description: 'ALJOC is one of the leading Motor Oil companies in Saudi Arabia.',
        image: ajlImage,
        // backgroundImg: bawsala_headerBackground,
        otherprojectImg:ajlImage,
        section1: {
            title: 'Creating innovative streaming experiences for communities.',
            ourRole: 'UX/UI, Product Design, Web Development, Testing, Deployment',
            company: 'Abdul Latif Jameel'
        },
        section2:{
            img: ajl_section2
        },
        section3:{
            title:'Product Listing',
            description:'View range of line of products provided by ALJOC based on the vehicle, make, engine type, etc.',
            img:ajl_section2
        },
        section4:{
            // title:'Lorem ipsum',
            // description:'LoLorem ipsum dolor sit amet, consectetur adipiscing elit. Potenti maecenas commodo habitant suspendisse pretium. Nec sem turpis purus mauris. Elementum ultrices eu arcu pretium lectus ante faucibus accumsan. Sagittis, tellus justo, sit mi a ut diam donec. Arcu.',
            imgLeft:ajl_section4Left,
            imgRight:ajl_section4Right
        },
        imgSection: { img: ajl_section5_background }
    },
    {
        icon: molemIcon,
        workImg: molemImage,
        title: 'Mulim',
        type:'web',
        description: 'The investment awareness program ‘Mulim’ was launched by the securities authorities in the Gulf Cooperation Council countries, which aims to raise awareness of the culture of financial transactions and investment in the financial markets, through a number of awareness campaigns and events offered by the program.',
        image: molemImage,
        backgroundImg: molem_headerBackground,
        backgroundSize:'cover',
        backgroundPosition:'center center',
        otherprojectImg:molemImage,
        section1: {
            title: 'Creating innovative streaming experiences for communities.',
            ourRole: 'UX/UI, Product Design, Web Development, Testing, Deployment',
            company: 'CMA'
        },
        section2:{
            img: molem_section2
        },
        section3:{
            title:'Smart Investor Award',
            description:'Showing all the tracks for the compition and the awards.',
            img:molem_section3
        },
        section4:{
            // title:'Lorem ipsum',
            // description:'LoLorem ipsum dolor sit amet, consectetur adipiscing elit. Potenti maecenas commodo habitant suspendisse pretium. Nec sem turpis purus mauris. Elementum ultrices eu arcu pretium lectus ante faucibus accumsan. Sagittis, tellus justo, sit mi a ut diam donec. Arcu.',
            imgLeft:molem_section4Left,
            imgRight:molem_section4Right
        },
        imgSection: { img: molem_section5_background }
    },
    {
        icon: investorsMineIcon,
        workImg: IM_HeaderImage,
        title: 'Investors Mine',
        type:'mobile',
        description: 'Investors Mine is a platform for Angel investors and VCs to find and browse investment opportunities. Also, book meetings with the founders to learn more about their products and ideas.',
        image: IM_HeaderImage,
        backgroundImg: im_headerBackground,
        backgroundSize:'cover',
        backgroundPosition:'center center',
        otherprojectImg:IM_HeaderImage,
        section1: {
            title: 'Creating innovative streaming experiences for communities.',
            ourRole: 'UX/UI, Product Design, Web Development, Testing, Deployment',
            company: 'Investors Mine'
        },
        sections: [
            {
                title: 'Investment Opportunities',
                description: 'View list of different opportunities from different domains which are looking for investments.',
                img: im_section3
            },
            {
                title: 'Opportunity Details',
                description: 'Know more about the opportunity by watching product video or reading relevant documents.',
                img: im_section4
            },
            {
                title: 'User Profile',
                description: 'View your investments and customize your settings as per your needs.',
                img: im_section2
            }
        ],
        imgSection: { img: im_section5_background }
    },
]