import React from 'react'
import BasicLayout from '../../Layout/BasicLayout/BasicLayout'
import styles from "./HomePage.module.scss"
import { useNavigate } from 'react-router-dom'
import { FiTwitter, FiInstagram, FiLinkedin, FiArrowUpRight, FiCheck } from "react-icons/fi";
// import CommentsCarousel from '../../Components/CommentsCarousel/CommentsCarousel'
import projectManagementIcon from "../../Assets/Service/projectManagementIcon.svg"
import uiUxIcon from "../../Assets/Service/uiUxIcon.svg"
import mobAppIcon from "../../Assets/Service/mobAppIcon.svg"
import digitalMarketingIcon from "../../Assets/Service/digitalMarketingIcon.svg"
import hireIcon from "../../Assets/Service/hireIcon.svg"
import webDevIcon from "../../Assets/Service/webDevIcon.svg"
import LastContainer1 from '../../Components/LastContainer/LastContainer1';
import ServiceCard from '../../Components/ServiceCard/ServiceCard';
import { workArray } from '../../Layout/Constants/ProjectsDetails';
import home from '../../Assets/Home/home.png'

const HomePage = () => {
  const navigate = useNavigate()
  const socialLinks = [
    {
      link: FiTwitter,
      // url: "https://devopscommunity.org",
    },
    {
      link: FiInstagram,
      // url: "https://twitter.com/maktechio",
    },
    {
      link: FiLinkedin,
      url: "https://www.linkedin.com/company/14566567",
    },
  ]

  const serviceArray = [
    {
      icon: uiUxIcon,
      path: '/services',
      title: 'UX/UI Design',
      text: 'Our approach to UI/UX is psychological. We transform your thoughts into action by taking the user-centric approach and making your ideas a reality!',
      tags: [
        { title: 'Job Finder', color: 'rgba(255, 255, 255, 1)' },
        { title: 'Project Management', color: 'rgba(255, 104, 70, 1)' },
      ]
    },
    {
      icon: webDevIcon,
      path: '/services',
      title: 'Web Development',
      text: 'Websites that are unique and tailored are a key requisite in today’s technologically driven market and that is what our developers will give for you!!',
      tags: [
        { title: 'Job Finder', color: 'rgba(255, 255, 255, 1)' },
        { title: 'CRM', color: 'rgba(255, 255, 255, 1)' },
        { title: 'Real Estate', color: 'rgba(255, 255, 255, 1)' },
      ]
    },
    {
      icon: mobAppIcon,
      path: '/services',
      title: 'Mobile App',
      text: 'Our customized mobile apps ensure ease and adaptability to users. We use the latest technology to develop IOS/Andriod apps that are sure to drive your business towards success!!',
      tags: [
        { title: 'Saas', color: 'rgba(245, 193, 33, 1)' },
        { title: 'CRM', color: 'rgba(255, 255, 255, 1)' },
        { title: 'Real Estate', color: 'rgba(255, 255, 255, 1)' },
      ]
    },
    {
      icon: projectManagementIcon,
      path: '/services',
      title: 'AR/VR Development',
      text: 'We help businesses come up with Augmented Reality use-cases that can enhance customer engagement and help them create a unique brand identity. We also, create an interactive and immersive Virtual Reality experience, in order to help your customers understand your products and offerings efficiently.',
      tags: [
        { title: 'Job Finder', color: 'rgba(255, 255, 255, 1)' },
        { title: 'CRM', color: 'rgba(125, 76, 176, 1)' },
        { title: 'Saas', color: 'rgba(245, 193, 33, 1)' },
      ]
    },
    {
      icon: hireIcon,
      title: 'Hire a Developer',
      path: '/contact',
      text: 'Developing apps and programs is an art for us! If you are looking for a developer that really gets what accuracy and attention to small detail means to a project, you are in the right place!! We have a totally dedicated and driven team of developers who are extremely creative and highly organized with excellent problem-solving ability.  They collaborate with various stakeholders to understand their needs and deliver exactly what is expected within the given timelines!',
      tags: [
        { title: 'Job Finder', color: 'rgba(255, 255, 255, 1)' },
        { title: 'CRM', color: 'rgba(255, 255, 255, 1)' },
        { title: 'Real Estate', color: 'rgba(255, 255, 255, 1)' },
      ]
    },
    {
      icon: digitalMarketingIcon,
      path: '/contact',
      title: 'Digital Marketing',
      text: 'Brand presence and targeted media presence is essential to position products and services and to this end, we will popularize your business with the perfect outreach!',
      tags: [
        { title: 'Saas', color: 'rgba(245, 193, 33, 1)' },
        { title: 'CRM', color: 'rgba(255, 255, 255, 1)' },
        { title: 'Real Estate', color: 'rgba(255, 255, 255, 1)' },
      ]
    },
    
    
  ]
  return (
    <BasicLayout>
      <div className='mainPaddingContainer'>
        <div className={`${styles.container1} row align-items-end`}>
          <div className='col-12 col-lg-8'>
            <h1 className={`${styles.heading} heading1`}>We are a <span>Professional</span> <br /> full service digital agency.</h1>
            <p className={`text1 ${styles.text}`} >“Great user experience isn’t a destination, it’s a constant journey – Elle Robertson.” This line aptly indicates our vision at UEX!  When an experience is beyond one’s expectation, it creates an urge to yearn for the same yet again.  As our tagline states, Redefining the User Experience, we aim to provide the best of quality services that will want you to re-experience it!</p>
          </div>
          <div className={`col-12 col-lg-4 ${styles.btnContainer}`}>
            <button className={`btn_medium`} onClick={() => navigate('/about')}>More about Us <FiArrowUpRight /></button>
          </div>
        </div>
        <div className={styles.socialContainer}>
          <div style={{ paddingRight: '10px' }}>
            {socialLinks?.map((item) => {
              return (
                <a target="_blank" rel="noreferrer" href={item?.url} style={{ padding: "5px" }}>
                  <item.link className={styles.socialIcon} />
                </a>
              )
            })}
          </div>
          <div className='flex-fill'>
            <hr className={styles.divider} />
          </div>
        </div>
      </div>
      <div className='mainPaddingContainer'>
        <div className={styles.container2}>
          <div className='row'>
              <img src={home} alt='homeImg'/>
          </div>
        </div>
      </div>
      <div className='mainPaddingContainer'>
        <div className={styles.container3}>
          <div className='row'>
            <div className='col-12 col-lg-5'>
              <h1 className={`heading2 ${styles.heading}`}>About Us</h1>
              <p className={`text1 ${styles.text}`}>As a full-service digital agency, we work closely with our clients to define, design and develop transformative user experiences across all platforms and brand’s touchpoints.</p>
              <button className={`${styles.btn_knowMore} btn_medium mobHidden`} onClick={() => navigate('/about')}>Know More <FiArrowUpRight /></button>
            </div>
            <div className='col-12 col-lg-1'></div>
            <div className='col-12 col-lg-6'>
              <div className={`${styles.feedbackBox} row`}>
                <div className='col'>
                  <div className={styles.feedbackCard}>
                    <div className={styles.circle}>
                      <h1>100+</h1>
                    </div>
                    <p className={styles.textBox}>Happy Customers</p>
                  </div>
                </div>
                <div className='col'>
                  <div className={styles.feedbackCard}>
                    <div className={styles.circle}>
                      <h1>100+</h1>
                    </div>
                    <p className={styles.textBox}>Completed projects</p>
                  </div>
                </div>
              </div>
              <div className={`row ${styles.points}`}>
                <div className='d-flex flex-row flex-start'>
                  <div><div className={styles.tick}><FiCheck /></div></div>
                  <div className={styles.textContainer}>
                    <p>We are looking to create a lot more happy customers and hungry for more creativity!! Our creative doors are open for you!! Need we say more?!!</p>
                  </div>
                </div>
                <div className='d-flex justify-content-center'>
                  <button className={`${styles.btn_knowMore} btn_medium mobShow`} onClick={() => navigate('/about')}>Know More <FiArrowUpRight /></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mainPaddingContainer'>
        <div className={styles.container4}>
          <h2 className={`${styles.heading} heading2`}>Recent Works</h2>
          <div className='row g-4 g-md-5'>
            {workArray.slice(0,3)?.map((work, index) => {
              return (
                <div className={`container-fluid ${styles.workContainer}`}>
                  <div className={`row align-items-center ${index % 2 === 0 ? null : 'flex-sm-row-reverse'}`}>
                    <div className='col-12 col-sm-6'>
                      <img src={work?.workImg} className={styles.workImage} alt='workImage' />
                    </div>
                    <div className='col-12 col-sm-6'>
                      <div className={`container ${styles.recentTextContainer}`}>
                        <div className='row'>
                          <img src={work?.icon} className={styles.workImg} alt='workIcon' />
                          <h1 className={`title1 ${styles.title}`}>{work?.title}</h1>
                          <button className={`btn_medium`} style={{ width: 'auto' }} onClick={() => navigate(`/work/${work.title}`)}>View Case Study <FiArrowUpRight /></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className='row'>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <button className={`${styles.btn_workAll} btn_medium`} onClick={() => navigate('/work')}>See All Works <FiArrowUpRight /></button>
            </div>
          </div>
        </div>
      </div>
      <div className='mainPaddingContainer'>
        <div className={styles.container5}>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <h1 className={`heading3 ${styles.heading}`}>What services do we <span>offer</span>?</h1>
              <p className={`text1 ${styles.text}`}>Each service we offer comes with an inspiration that will surpass your imagination.</p>
            </div>
            <div className={`col-12 col-md-6 ${styles.btnContainer}`}>
              <button className={`${styles.btn_knowMore} btn_medium mobHidden`}>Our Services <FiArrowUpRight /></button>
            </div>
          </div>
          <div className="row g-4 g-md-5">
            {serviceArray?.map((service) => {
              return (
                <div className={`col-12 col-md-6`}>
                  <ServiceCard item={service} />
                </div>
              )
            })}
          </div>
          <div className={`${styles.btnContainer}`}>
            <button className={`${styles.btn_knowMore} btn_medium mobShow`} style={{ marginTop: '30px' }}>Our Services <FiArrowUpRight /></button>
          </div>
        </div>
      </div>
      {/* <CommentsCarousel /> */}
      <LastContainer1 />
    </BasicLayout>
  )
}

export default HomePage