import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from "./ServiceCard.module.scss"

const ServiceCard = ({ item }) => {
    const navigate = useNavigate()
    return (
        <div className={styles.serviceCard} style={{cursor:item?.path? 'pointer':null}} onClick={()=>navigate(item?.path)}>
            <div className={styles.titleContainer}>
                <img src={item?.icon} alt='icon' style={{ width: '49px' }} />
                <h1 className='mb-0 p-3'>{item?.title}</h1>
            </div>
            <div className='row'>
                <p>{item?.text}</p>
            </div>
            {/* <div className={styles.tagContainer}>
                {item?.tags ? item?.tags.map((tag) => {
                    return (
                        <span className={styles.tagSpan} style={{ color: tag?.color }}>{tag?.title}</span>
                    )
                }) : null}
            </div> */}
        </div>
    )
}

export default ServiceCard